import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { TOTAL_SEASONS_DATA } from "@/game-destiny2/constants/season.mjs";
import { BungieProfileValidator } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import { MatchValidator } from "@/game-destiny2/models/destiny2-match.mjs";
import { MatchListValidator } from "@/game-destiny2/models/destiny2-match-list.mjs";
import { SeasonValidate } from "@/game-destiny2/models/destiny2-seasons.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData([bungieId, seasonId, gameId]: [
  string,
  string,
  string,
]) {
  try {
    const requiredPromises = [];
    const sideLoadPromises = [];

    requiredPromises.push(
      getData(API.getProfile({ bungieId }), BungieProfileValidator, [
        "destiny2",
        "profiles",
        bungieId,
      ]),
      getData(API.getMatch({ activityId: gameId, seasonId }), MatchValidator, [
        "destiny2",
        "match",
        gameId,
      ]),
    );

    // TODO: add fallback to bungie
    const seasons = await getData(API.getSeasons(), SeasonValidate, [
      "destiny2",
      "seasons",
    ]);
    const seasonIds = seasons
      ?.slice(0, TOTAL_SEASONS_DATA)
      .map((s) => s.id) ?? [seasonId];

    await Promise.all(
      seasonIds.map((seasonId) =>
        getData(
          API.getMatchList({ bungieId, seasonId }),
          MatchListValidator,
          ["destiny2", "matchList", bungieId],
          { mergeFn: API.matchListMerge },
        ),
      ),
    );

    sideLoadPromises.push(
      ...(readState.destiny2.matchList[bungieId]?.map(({ id, seasonId }) =>
        getData(
          API.getMatch({ activityId: id, seasonId }),
          MatchValidator,
          ["destiny2", "match", id],
          { shouldFetchIfPathExists: false },
        ),
      ) ?? []),
    );

    Promise.all(sideLoadPromises);

    return await Promise.all(requiredPromises);
  } catch (e) {
    devError("Failed to fetch match page data", e);
  }
}
